import React, { useEffect } from "react";
import { Bounce, ToastContainer } from "react-toastify";
import RoutesComponent from "./view/shared/routes/RoutesComponent.tsx";
import "react-toastify/dist/ReactToastify.css";
import "./present2.css";
import "./template.css";
import "./App.css";
import ConfettiGenerator from "./confetti.js";

const App = () => {
  useEffect(() => {
    const selectedTheme = localStorage.getItem("theme");
    if (!selectedTheme) {
      localStorage.setItem("theme", "dark");
    }

    const confettiSettings = {
      target: document.getElementById("my-canvas"),
    };
    const confetti = ConfettiGenerator(confettiSettings);
    confetti.render();

    const tgWebApp = (window as any).Telegram.WebApp;

    if (tgWebApp) {
      // Mark the WebApp as ready
      tgWebApp.ready();
      // Request the app to expand to full screen
      setTimeout(() => {
        tgWebApp.expand();
      }, 100); // Add a small delay to ensure the ready state
    } else {
      alert("Telegram Web App API not found.");
    }

    return () => confetti.clear();
  }, []);

  return (
    <>
      <ToastContainer transition={Bounce} />
      <RoutesComponent />
    </>
  );
};

export default App;
