import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { isUser } from "../../../redux/slice/userDataSlice.tsx";

export default function PrivateRoute() {
  const isUserLoggedIn = useSelector(isUser)
  if (!isUserLoggedIn) {
    return <Navigate to={"/"} />;
  }
  return <Outlet />;
}