import React from "react";
import { lazy, Suspense } from "react";
import BallLoader from "./BallLoader.tsx";
export default function CustomLoadable(opts) {
  const LazyComponent = lazy(opts.loader);

  return (props) => (
    <Suspense fallback={<BallLoader/>}>
      <LazyComponent {...props} />
    </Suspense>
  );
}