import { configureStore } from "@reduxjs/toolkit";
import userDataReducers from "./slice/userDataSlice.tsx";
import BalanceReducers from "./slice/BalanceSlice.tsx";

const store = configureStore({
  reducer: {
    userData: userDataReducers,
    balanceData: BalanceReducers
  },
});

export default store;
