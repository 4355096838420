import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userData: JSON.parse(sessionStorage.getItem("userData")) || null,
  isUserLoggedIn: Boolean(sessionStorage.getItem("userData")) ? true : false,
};

const userDataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
      state.isUserLoggedIn = true;
      sessionStorage.setItem("userData", JSON.stringify(action.payload));
    },
    clearUserData: (state) => {
      state.userData = null;
      state.isUserLoggedIn = false;
      sessionStorage.removeItem("userData");
    },
  },
});
export const { setUserData, clearUserData } = userDataSlice.actions;
export const isUser = (state) => state.userData.isUserLoggedIn;
export const userData = (state) => state.userData.userData;
export default userDataSlice.reducer;