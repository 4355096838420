// src/balanceSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Async thunk to fetch balance
export const fetchBalance = createAsyncThunk(
  "balance/fetchBalance",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BINGO_RAM_API_URL}/postLogin/getBalance`,
         params 
      );
      return response.data.playerLoginInfo.practiceBal;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const balanceSlice = createSlice({
  name: "balance",
  initialState: {
    balance: 0.00,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBalance.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBalance.fulfilled, (state, action) => {
        state.loading = false;
        state.balance = action.payload;
      })
      .addCase(fetchBalance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

const balanceLoading = (state) => state.balanceData.loading;
const balanceData = (state) => state.balanceData.balance;
const balanceError = (state) => state.balanceData.error;

export { balanceData, balanceError, balanceLoading };
export default balanceSlice.reducer;
