import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { routes } from "../../routes.tsx";
import CustomLoadable from "../CustomLoadable.tsx";
import PrivateRoute from "./PrivateRoute.tsx";
import PageNotFound from "../PageNotFound.tsx";

export default function RoutesComponent() {
  return (
    <BrowserRouter>
      <Routes>
        {routes.publicRoutes.map((route) => {
          const LazyComponent = CustomLoadable({ loader: route.loader });
          return (
            <Route
              key={route.path}
              path={route.path}
              element={<LazyComponent />}
            />
          );
        })}
        <Route element={<PrivateRoute />}>
          {routes.privateRoutes.map((route) => {
            const LazyComponent = CustomLoadable({ loader: route.loader });
            return (
              <Route
                key={route.path}
                path={route.path}
                element={<LazyComponent />}
              />
            );
          })}
        </Route>
        <Route path="*" element={<PageNotFound />}/>
      </Routes>
    </BrowserRouter>
  );
}
