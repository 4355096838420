import React from 'react'

export default function PageNotFound() {
  return (
    <div className='pagenotfound'>
        <h1>404 Page Not Found</h1>
    </div>
  )
  
}
