import React, { useState, useEffect } from "react";

export default function BallLoader() {
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setOpacity(0);
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, []);
  return (
    <div className="d-flex justify-content-center">
      <div
        className="sp-pre-loader"
        style={{
          opacity,
          transition: "opacity 1s ease-in-out",
        }}
      >
        <div className="loading-wrap">
          <div className="bounceballs">
            <div className="ballWrap bbw1">
              <div className="ball ball1">
                <img src="/images/icons/icon-ballRed.png" alt="red-ball" />
              </div>
            </div>
            <div className="ballWrap bbw2">
              <div className="ball ball2">
                <img
                  src="/images/icons/icon-ballOrange.png"
                  alt="orange-ball"
                />
              </div>
            </div>
            <div className="ballWrap bbw3">
              <div className="ball ball3">
                <img src="/images/icons/icon-ballBlue.png" alt="blue-ball" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
/* style="opacity: 0.345492;" */